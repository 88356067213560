exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-club-js": () => import("./../../../src/pages/club.js" /* webpackChunkName: "component---src-pages-club-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-contentful-news-id-js": () => import("./../../../src/pages/news/{ContentfulNews.id}.js" /* webpackChunkName: "component---src-pages-news-contentful-news-id-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-players-contentful-player-rank-js": () => import("./../../../src/pages/players/{ContentfulPlayer.rank}.js" /* webpackChunkName: "component---src-pages-players-contentful-player-rank-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-site-notice-js": () => import("./../../../src/pages/siteNotice.js" /* webpackChunkName: "component---src-pages-site-notice-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-teams-contentful-team-name-js": () => import("./../../../src/pages/teams/{ContentfulTeam.name}.js" /* webpackChunkName: "component---src-pages-teams-contentful-team-name-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */)
}

